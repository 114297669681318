<template>
  <div class="home" v-if="isReloaded">
    <IframeContainer :src="src" v-if="src.trim() != ''"/>
  </div>
</template>

<script>
import config from '../../../../../config'
import bus from '@/utils/bus'
import { IframeContainer } from '../render/components'

export default {
  name: 'Home',

  data() {
    return {
      isReloaded: true,
      src: ''
    }
  },

  components: {
    IframeContainer
  },

  mounted() {
    this.src = `http://${config.baseHost}/crm/swork/index.html#/home`
    bus.$on('homeReload', this.reload)
  },

  beforeDestroy() {
    bus.$off('homeReload', this.reload)
  },

  methods: {
    reload() {
      this.isReloaded = false
      this.$$nextTick(() => {
        this.isReloaded = true
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  background-color: #f0f2f5;
}
</style>
