<template>
  <div class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <subsystem id="subsystem-container" class="subsystem-container" />

    <div class="right-menu">
      <header-search id="header-search" class="right-menu-item hover-effect" />

      <error-log class="errLog-container right-menu-item hover-effect" />

      <screenfull id="screenfull" class="right-menu-item hover-effect" />

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="userAvatar" class="user-avatar" alt="avatar" />
          <span class="username">{{user.username}}</span>
          <span class="brand" v-if="choosedBrand">（{{choosedBrand.TEN_NAME}}）</span>
          <i class="el-icon-caret-bottom" style="font-size: 10px;" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <template v-if="brands.length">
            <el-dropdown-item
              v-for="brand in brands"
              :key="brand.USERID"
              icon="el-icon-medal"
              :disabled="brand.isChoosed"
              @click.native="chooseBrand(brand)"
            >{{brand.TEN_NAME}}</el-dropdown-item>
          </template>
          <router-link to="/profile">
            <el-dropdown-item :divided="!!brands.length" icon="el-icon-magic-stick">控制台</el-dropdown-item>
          </router-link>
          <el-dropdown-item icon="el-icon-key" @click.native="showChangePassDialog">
            修改密码
          </el-dropdown-item>
          <el-dropdown-item icon="el-icon-user" @click.native="logout">
            登出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 修改密码弹框 -->
    <ChangePassDialog :visible.sync="visibleChangePassDialog" :isNewUser="user.needpsd" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  Subsystem,
  Hamburger,
  ErrorLog,
  Screenfull,
  HeaderSearch,
  ChangePassDialog
} from '@/pages/render-layout/components/index.js'
import defaultAvatar from '@/assets/defaultAvatar.jpeg'
import bus from '@/utils/bus'

export default {
  components: {
    Subsystem,
    Hamburger,
    ErrorLog,
    Screenfull,
    HeaderSearch,
    ChangePassDialog
  },

  data() {
    return {
      visibleChangePassDialog: false
    }
  },

  computed: {
    ...mapState(['sidebar', 'user', 'avatar', 'device', 'brands']),
    userAvatar() {
      return this.avatar || defaultAvatar
    },
    choosedBrand() {
      const matched = this.brands.filter(brand => brand.isChoosed)
      if (!matched) {
        return null
      }
      return matched[0]
    }
  },

  mounted() {
    this.shouldNewUserChangePass()
  },

  methods: {
    shouldNewUserChangePass() {
      if (this.user.needpsd) {
        this.showChangePassDialog()
      }
    },
    toggleSideBar() {
      this.$store.dispatch('toggleSideBar')
    },
    async chooseBrand(brand) {
      await this.$store.dispatch('chooseBrand', brand.USERID)
      this.$message.success(`已成功切换为：${brand.TEN_NAME}`)
      // 刷新页面
      bus.$emit('closeAllView')
      window.location.reload()
    },
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push('/login')
    },
    toggleChangePassDialog() {
      this.visibleChangePassDialog = !this.visibleChangePassDialog
    },
    showChangePassDialog() {
      this.visibleChangePassDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .subsystem-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    display: flex;
    align-items: center;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      padding: 0 16px;
      color: #5a5e66;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }

    .avatar-container {
      height: 50px;
      display: flex;
      align-items: center;
      margin-right: 15px;

      .avatar-wrapper {
        display: flex;
        align-items: center;

        .user-avatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }

        .username {
          margin: 0 4px 0 8px;
        }
      }
    }
  }
}
</style>
