<template>
  <div class="tags-view">
    <scroll-pane ref="scrollPane" class="tags-view-wrapper">
      <router-link
        v-for="tag in visitedViews"
        ref="tag"
        :key="tag.path"
        :class="isActive(tag) ? 'active' : ''"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        tag="span"
        class="tags-view-item"
        @click.middle.native="closeSelectedTag(tag)"
        @contextmenu.prevent.native="openMenu(tag, $event)"
      >
        {{ tag.title }}
        <span
          v-if="!tag.meta.affix"
          class="el-icon-close"
          @click.prevent.stop="closeSelectedTag(tag)"
        />
      </router-link>
    </scroll-pane>
    <ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="contextmenu">
      <!-- <li @click="refreshSelectedTag(selectedTag)">刷新</li> -->
      <li
        v-if="!(selectedTag.meta && selectedTag.meta.affix)"
        @click="closeSelectedTag(selectedTag)"
      >关闭</li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags(selectedTag)">关闭所有</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ScrollPane from './ScrollPane'
import path from 'path'
import bus from '@/utils/bus'

export default {
  components: { ScrollPane },

  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: []
    }
  },

  computed: {
    ...mapState(['renderRoute', 'visitedViews', 'permissionRoutes'])
  },

  watch: {
    $route() {
      this.addTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },

  mounted() {
    this.initBus()
    this.initTags()
    this.addTags()
  },

  beforeDestroy() {
    this.removeBus()
  },

  methods: {
    initBus() {
      bus.$on('openView', this.openView)
      bus.$on('goToView', this.goToView)
      bus.$on('openTabView', this.openTabView)
      bus.$on('backView', this.backView)
      bus.$on('closeAllView', this.closeAllView)
    },
    removeBus() {
      bus.$off('openView', this.openView)
      bus.$off('goToView', this.goToView)
      bus.$off('openTabView', this.openTabView)
      bus.$off('backView', this.backView)
      bus.$off('closeAllView', this.closeAllView)
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    getActiveView() {
      const matched = this.visitedViews.filter(view => view.path === this.$route.path)
      return matched.length ? matched[0] : {}
    },
    filterAffixTags(routes, basePath = '/') {
      let tags = []
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path)
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: { ...route.meta }
          })
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path)
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags]
          }
        }
      })
      return tags
    },
    getCurrentTag(routes, basePath = '/') {
      let tags = []
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path)
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: { ...route.meta }
          })
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path)
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags]
          }
        }
      })
      return tags
    },
    initTags() {
      const affixTags = (this.affixTags = this.filterAffixTags(this.permissionRoutes))
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch('addVisitedView', tag)
        }
      }
    },
    openTabView({ pageId } = {}) {
      this.$router.push(`/page/${pageId}`)
    },
    addTags() {
      const { fullPath, name, path, meta } = this.$route
      if (name) {
        // Page need dynamic title
        if (name === 'page') {
          this.$store.dispatch('addView', {
            fullPath,
            name,
            path,
            meta,
            ...this.renderRoute
          })
        } else {
          this.$store.dispatch('addView', { fullPath, name, path, meta })
        }
      }
      return false
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag)
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('updateVisitedView', this.$route)
            }
            break
          }
        }
      })
    },
    refreshSelectedTag(view) {
      this.$store.dispatch('delCachedView', view).then(() => {
        const { fullPath } = view
        this.$nextTick(() => {
          this.$router.replace({
            path: '/redirect' + fullPath
          })
        })
      })
    },
    closeSelectedTag(view) {
      this.$store.dispatch('delView', view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view)
        }
      })
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag)
      this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeAllTags(view) {
      this.$store.dispatch('delAllViews').then(({ visitedViews }) => {
        if (this.affixTags.some(tag => tag.path === view.path)) {
          return
        }
        this.toLastView(visitedViews, view)
      })
    },
    openView({ url, pageId, query, title }) {
      const activeView = this.getActiveView()
      // 排重
      const matched = activeView.history.filter(v => {
        return (url && v.url === url) || (pageId && v.pageId === pageId)
      })
      if (!matched.length) {
        activeView.history.push({ url, pageId, query, title })
        bus.$emit('getBreadcrumb')
      }
    },
    backView(callback = () => {}, index = 0) {
      const activeView = this.getActiveView()
      if (!activeView.history.length) {
        return
      }
      return activeView.history.splice(index)
    },
    goToView(url) {
      // 没有 url 代表返回根视图
      const activeView = this.getActiveView()
      if (!url) {
        return activeView.history.splice(0)
      }
      let index = -1
      activeView.history.forEach((v, i) => {
        if (url === v.url) {
          index = i
        }
      })
      if (index !== -1) {
        // 保留当前历史，清除队列后面全部历史
        return activeView.history.splice(index + 1)
      } else {
        return activeView.history.splice(0)
      }
    },
    closeAllView() {
      this.closeAllTags(this.selectedTag)
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.path)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'home') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      this.top = e.clientY
      this.visible = true
      this.selectedTag = tag
    },
    closeMenu() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/var.scss';

.tags-view {
  .tags-view-wrapper {
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 32px;
      line-height: 32px;
      color: #3a3a3a;
      margin-left: 24px;
      margin-top: 4px;
      &:hover {
        color: $primary;
      }
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 40px;
      }
      &::after {
        display: none;
        content: '';
        position: absolute;
        height: 2px;
        bottom: -4px;
        left: -12px;
        right: -12px;
        border-radius: 2px;
        background-color: $primary;
      }
      &.active {
        color: $primary;
        &::after {
          display: block;
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
// reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    position: relative;
    &:hover .el-icon-close {
      opacity: 1;
    }

    /deep/ .el-icon-close {
      position: absolute;
      right: -18px;
      top: 9px;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;

      &:before {
        transform: scale(0.7);
        display: inline-block;
      }

      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
